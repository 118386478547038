import React, { Component } from "react"
import AboutSection from "./aboutSection"
import CommitteeMembers from "./committee"
import KytsTeam from "./kytsTeam"
import MissionSection from "./mission"
import TestimonialsList from "./testimonials"
import AboutTitle from "./title"

export default class EventTicketing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arrayCommitteeYears: []
    }
  }

  componentDidMount() {
    var arraylocalCommitteeYears = [];
    for (var i = 2004; i <= (new Date()).getFullYear() + 3; i += 3) {
      //console.log((i - 1) + " - " + (i + 1));
      arraylocalCommitteeYears.push((i - 1) + " - " + (i + 1));
    }
    this.setState({
      arrayCommitteeYears: arraylocalCommitteeYears
    });
  }
  render() {
    return (<>
      <AboutTitle />
      <AboutSection />

      <KytsTeam />
      <TestimonialsList />
    </>
    )
  }
}

