import React from "react"
import UseHttpRequest from "../../hooks/HttpRequestSponsors"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
const TestimonialsList = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const allData = UseHttpRequest()
  if (allData.length <= 0) {
    return (
      <div className="row justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  const data = allData.Sponsors
  console.log(data)
  return (
    <>
      <section className="testimonials-section pt-70 pb-70">
        <div className="container">
          <div className="section-title">
            <span>
              <i className="flaticon-testimonial"></i>
              Testimonials
            </span>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <Slider {...settings}>
                <div className="feedback-item">
                  <div className="content">
                    <h3>2019 Diwali - Vazhai Ilai Virundhu</h3>
                    <p>
                      1st time ever in USA I had Vazhi Ilai Virunthu. It gave me an experience of attending a marriage back in India while entering the dining hall, the smell remained me that I was typically in a marriage in my native. Some one was wiping his hand in his dothi that was typically brought my village in front of my eyes. Excellent experience in total.
                    </p>
                  </div>
                </div>
                <div className="feedback-item">
                  <div className="content">
                    <h3>2019 - Tamil New Year Celebration</h3>
                    <p>
                      First time I have seen a true Tamil culture related program in out Tamil Sangam - I meant to say The Parai Team. I always expect Tamil Sangam to provide huge space for Tamil cultural and literature events in the programs list. This is the first time I have ever seen huge space for Tamil cultural programs (other than usual boring filim song dances). I was so happy to see Thavil, Silambu (long stick), Urumi, Udukkai, Pambai, Silambu live in USA for very first time in my USA life. Parai program was extra ordinary. I appreciate the organizers of Parai team.
                      </p>
                  </div>
                </div>
                <div className="feedback-item">
                  <div className="content">
                    <h3>2020 - Pongal Celebration</h3>
                    <p>
                      Food was excellent and people all committee members served very well respected way... thank you for food committee.
                      </p>
                  </div>
                </div>

              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TestimonialsList
