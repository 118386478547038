import React from "react"
import { Link } from "gatsby"
import UseHttpRequest from "../../hooks/HTTPRequestMembers"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
const KytsTeam = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  }
  const allData = UseHttpRequest()
  if (allData.length <= 0) {
    return (
      <div className="row justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  const data = allData.Members.filter((Member) => Member.IsCommitteeMember === true && Member.CommitteeYear === (new Date()).getFullYear()).sort((a, b)=>a.CommitteePosition>b.CommitteePosition?1:-1)
  return (
    <>
      {
        console.log(allData)
      }
      {
        console.log("data", data)
      }
      <section className="team-section pt-70 pb-70">
        <div className="container">
          <div className="team-title-area">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="section-title text-left">
                  <span>
                    <i className="flaticon-support" />
                    Meet Our Team
                  </span>
                  <h2>Talented Team behind KYTS</h2>
                  <p>
                    KYTS appreciates all current and past members, volunteers
                    and committee members hard work and dedication since 2003.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="team-btn text-right">
                  <Link to="/kyts-members" className="optional-btn">
                    Committee
                    <i className="flaticon-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <Slider {...settings}>
                {data.map((item, index) => (
                  <div key={index}>
                    <div className="single-team-member">
                      <div className="team-image">
                        <img src={item.PhotoURL} alt="" />
                      </div>

                      <div className="member-content">
                        <h3>{item.FName}</h3>
                        <span>{item.CommitteePosition.toString().substring(item.CommitteePosition.toString().indexOf(".") + 1)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default KytsTeam
