import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutPage from "../components/about/index"
const AboutusPage = () => {
  return (
    <Layout>
      <SEO
        title="About Us | Kentucky Tamil Sangam"
        desc="About Us | Kentucky Tamil Sangam"
        pathname="/about"
        keywords="About Us KYTS page"
      />
      <AboutPage />
    </Layout>
  )
}

export default AboutusPage
