import React from "react"
const AboutSection = () => {
  return (
    <>
      <section className="about-section pt-70 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-item">
                <span>
                  <i className="flaticon-care-about-plants" />
                  About us
                </span>
                <h3>Kentucky Tamil Sangam is a secular, non-profit and non-political organization</h3>
                <p className="main-color">
                  It has been serving the Kentuckiana area since 2003.It was started as a group for like-minded Tamils to socialize and has grown into a strong organization of what it is now.
                </p>
                <p>
                  We seek to promote and co-ordinate Tamil culture, educational and community activities. We are open to all persons interested in promoting the Tamil language and culture.
                  <br />
                  We sincerely thank the entire KYTS members and Family for your continued support and patronage.

                </p>
                <span>
                  <i className="flaticon-target" />
                  Our Mission
                </span>
                <p>
                  KYTS’s mission is to create and promote interest and knowledge of Tamil language and culture in the community, by organizing educational, literary, cultural, social and charitable activities.
                      </p>

                <div className="about-btn">
                  <a href="/register" className="optional-btn">
                    Join Now
                    <i className="flaticon-right" />
                    <span />
                  </a>

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-image about-four">
                <img
                  src="img/about/cricket-winner.jpg"
                  className="shadow"
                  alt=""
                />
                <img
                  src="img/about/about-400-300.jpg"
                  className="shadow"
                  alt=""
                />
              </div>
              <div className="about-video">
                <a
                  href="https://youtu.be/v__ajXA8D48" target="_blank"
                  className="video-btn popup-youtube"
                >
                  <i className="bx bx-play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutSection
